/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;

		.navbar-toggler-icon{
			background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
			border-color: $white;
		}
	}
}

@include media-breakpoint-down(lg){
	.navbar-nav{
		padding: 25px 0px;	
		background-color: #1f1e1e;
		margin-top: 25px;
		margin-bottom: 25px;		
	}
}

.navbar-toggler{
    background-color: $black;
    border: 0;
    border-radius: 0;	
}

.nav-link{
	color: $black;
	font-size: 15px;
	margin-left: 50px;
	position: relative;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 0px !important;

	@include media-breakpoint-down(lg){
    	padding-top: 20px !important;
    	padding-bottom: 0px !important;
		margin-left: 0;
		padding-left: 25px !important;

		color: $white;

		&:hover{
			color: $white;
		}
	}

	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: #18272F;
		bottom: 0;
		left: 0;
		transform-origin: right;
		transform: scaleX(0);
		transition: transform .4s $ease;
	}

	&:hover{
		color: $black;

		&:before{
			transform-origin: left;
			transform: scaleX(1);			
		}
	}
}

/**
 * Language switch wrapper
 **/
 .language-switch-wrapper{
	font-size: 14px;
    margin-left: 45px;
    position: relative;
    top: 3px;
	//border-right: 5px solid $black;

	@include media-breakpoint-down(lg){
		position: absolute;
    	right: 62px;
    	top: 8px;
		height: 38px;

		select{
			height: 100%;
		}
	}

	select{
		background-color: $black;
    	border: 0;
    	padding: 5px;
		padding-right: 0;
    	color: $white;
    	font-weight: 400;
    	text-transform: uppercase;
	}
 } 

#menu-entries{
    flex-direction: row-reverse;

	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}
