/**
 * Single page - default
 **/

.single-page-content{
    /**
     * Typography
     **/
     h1{
        @extend .knife-after;
        font-weight: 700;

        &:after{
            left: 0;
        }

        @include media-breakpoint-up(lg){
            font-size: 52px;
            line-height: 65px;
            margin-bottom: 125px !important;            
        }
    }

    h2{
        font-size: 32px;
        font-weight: 300;
        line-height: 30px;

        margin-top: 65px;
        margin-bottom: 30px;
    }

    h3{
        font-size: 26px;
        font-weight: 300;
        line-height: 24px;

        margin-top: 50px;
        margin-bottom: 25px;        
    }

    .wp-block-gallery{
        @extend .block-50;
    }
}
