/**
 * Blocks
 **/
.navigation-blocks{
    .navigation-block{
        margin-bottom: 110px;

        &__image-wrapper{
            overflow: hidden;
            position: relative;

            &:after{
                content:'';
                position: absolute;
                background-image: url('images/corner.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                height: 65px;
                width: 65px;
                right: 0;
                top: 0;
            }
        }

        &__title-wrap{
            position: relative;
        }

        &:hover{
            h2{
                transform: translateY(-20px);

                &:after{
                    width: 150px;
                    transform: translateY(20px);
                }
            }

            img{
                transform: scale(1.05);
            }
        }
    }

    h2{
        font-weight: 300;
        font-size: 36px;
        line-height: 42px;
        margin-left: 15px;
        position: absolute;
        bottom: -50px;
        padding-bottom: 25px;

        transition: all .5s $ease;

        @include media-breakpoint-up(lg){
            margin-left: 65px;
        }

        &:after{
            content: '';
            position: absolute;
            background-color: $white;
            width: 75px;
            height: 1px;

            left: 0;
            bottom: 0px;

            transition: all .5s $ease;   
        }
    }

    img{
        transition: all 1s $ease;   
    }

    a{
        color: $white;
    }
}

/**
 * EP
 **/
.elevator-pitch{
    padding-top: 50px;

    h2,
    p{
        max-width: 950px;
        margin: 0 auto;        
    }

    h2{
        @extend .knife-after;

        span{
            position: relative;
            --dynamic-width: 0%;

            &:after{
                content:'';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 3px;
                width: var(--dynamic-width);
                background-color: $white;
            }
        }
    }

    &__text{
        display: block;
    }

    p{
        margin-bottom: 25px;
    }
} 

/**
 * Realisaties
 **/
.selected-works{
    color: $black;

    p{
        max-width: 680px;
    }

    .work-wrap{
        margin-top: 25px;
    }
}

.work{
    margin-bottom: 40px;
    
    &:last-of-type{
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg){
       // margin-bottom: 0;
    }

    &:hover{
        img{
            transform: scale(1.05);
        }
    }

    .image-wrapper{
        position: relative;
        overflow:hidden;

        &:after{
            content:'';
            position: absolute;
            background-image: url('images/corner-white.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 65px;
            width: 65px;
            right: 0;
            top: 0;
        }        
    }

    h4{
        font-size: 22px;
        font-weight: 300;
        margin-top: 15px;
    }

    img{
        transition: all .8s $ease;
    }

    &__link{
        position: absolute;
        bottom: 10px;
        right: 0px;        
    }
}

/**
 * Call to action
 **/
.call-to-action{
    h2{
        @extend .knife-after;

        @include media-breakpoint-up(lg){
            line-height: 58px;
        }
    }

    p{
        max-width: 580px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}