/**
 * Typography
 **/
*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    background-color: $black;
    color: $white;
    font-family: $sans-serif-font;
    font-size: 18px;
    line-height: 30px;    
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $serif-font;
}

h1,
h2{
    font-weight: 300;
}

h3,
h4,
h5,
h6{
    font-weight: 700;
}

h2{
    font-weight: 700;

    @include media-breakpoint-up(lg){
        font-size: 52px;
        line-height: 72px;
    }
}

h3{
    font-weight: 300;

    @include media-breakpoint-up(lg){
        font-size: 52px;
        line-height: 65px;        
    }
}

/**
 * Classes
 **/

 .text-center{
	text-align: center !important;
}

.text-right{
	text-align: right !important;
}

/**
 * Buttons
 **/
.btn{
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 15px 15px;
    text-decoration: none;
    transition: all .4s $ease;
    position: relative;
    
    &:after{
        content: '';
        position: absolute;
        background-image: url('images/btn-knife.png');
        background-size: 100%;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        top: 21px;
        margin-left: 8px;
        opacity: 0;

        transition: all .4s $ease;
    }

    &:hover{
        padding-right: 40px;

        &:after{
            opacity: 1;
        }
    }

    &--white{
        background-color: $white;
        color: $black;

        &:after{
            background-image: url('images/btn-knife-black.png');
        }

        &:hover{
            color: $black;
        }
    }

    &--black{
        background-color: $black;
        color: $white;

        &:hover{
            color: $white;
        }
    }
}