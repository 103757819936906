/**
 * Header image
 **/
.header{
    .wrapper{
        padding-bottom: 100px;;
    }

    &--sub{
        .header-image{
            @include media-breakpoint-up(lg){
                height: 375px;
            }
        }
    }
}

.header-image{
    background-size: cover;
    background-position: center;
    height: 225px;
    
    display: flex;
    align-items: center;
    position: relative;
    top: -100px;

    @include media-breakpoint-up(md){
        margin-left: 30px;
        margin-right: 30px;
    }

    h1{
        font-weight: 700;
        opacity: 0;
        transform: translateY(20px);

        animation-name: reveal-in;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-duration: 1.5s;
        animation-delay: 1.5s;        

        span{
            display: block;
            font-family: $sans-serif-font;
            font-size: 24px;
            font-weight: 300;
            opacity: 0;
            transform: translateY(10px);            

            animation-name: reveal-in;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            animation-duration: 1.5s;
            animation-delay: 1.75s;     
        }
    }

    @include media-breakpoint-up(lg){
        height: 500px;

        h1{
            font-size: 72px;
            line-height: 66px;

            span{
                font-size: 32px;                
            }
        }
    }
}


@keyframes reveal-in{
    from {opacity: 0;}
    to {opacity: 1; transform: translateY(0px);}  
}
