/**
* Footer
**/
.footer{
    h5{
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 25px;
        margin-top: 35px;

        @include media-breakpoint-up(lg){
            margin-top: 0;
        }
    }

    ul{
        padding-left: 0;
        list-style-type: none;

        li a{
            color: $white;
            text-decoration: none;
        }
    }

    p,
    li,
    a{
        font-size: 16px;        
    }

    .logo-wrapper{
        h5{
            margin-top: 12px;

            span{
                display: block;
                font-size: 14px;
                font-family: $sans-serif-font;
                font-weight: 300;
            }
        }
    }

    a{
        position: relative;

        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $white;
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform .4s $ease;
        }
    
        &:hover{
            color: $white;
    
            &:before{
                transform-origin: left;
                transform: scaleX(1);			
            }
        }        
    }
}

.footer-border{
   border-top: 1px solid #3D3D3D;
   padding-bottom: 50px;
}

.footer-final{
    padding-top: 50px;
    padding-bottom: 30px;

    a{
        color: $white;
        font-size: 11px;
        text-decoration: none;
    }
}