.row:before, .row:after {
	display: none !important;
}

img{
	max-width: 100%;
}

/**
 * Backgrounds
 **/
 @each $name, $color in ('black': $black, 'white': $white) {
	.bg--#{$name} {
		background-color: $color;
  	}
}

/**
 * Block
**/
@each $name, $size in ('10': 10, '20': 20, '35': 35, '50': 50, '65': 65,'75': 75, '100': 100, '150': 150) {
	.block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}
} 

@include media-breakpoint-down(md){
	.block-100{
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.block-50-nt{
	padding-top: 0;
	padding-bottom: 50px;
}

@include media-breakpoint-down(sm) {
	.block-100.block-0-mobile{
		padding-top: 0;
		padding-bottom: 0;
	}
}

/**
 * Knife icon
 **/

.knife-after{
	position: relative;
	margin-bottom: 50px !important;

	&:after{
		content: '';
		position: absolute;
		background-image: url('images/icon-knife-white.png');
		background-repeat: no-repeat;
		background-size: 100%;
		height: 40px;
		width: 40px;
		left: calc(50% - 20px);
		bottom: -60px;
	}	
}