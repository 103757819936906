/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Fonts
 **/
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Noto+Sans:wght@300;600&display=swap');

$serif-font: 'Merriweather', serif;
$sans-serif-font: 'Noto Sans', sans-serif;

/**
 * Colors
 **/
$white: #FFFFFF;
$black: #1F1E1E;
 
$primary-color: $black;
$secondary-color: $white;

/**
 * Transition
 **/
 
$ease: cubic-bezier(0.33, 1, 0.68, 1);
